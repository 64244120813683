@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap);
:root {
  --ifm-dark-value: 10%;
  --ifm-darker-value: 15%;
  --ifm-darkest-value: 30%;
  --ifm-light-value: 15%;
  --ifm-lighter-value: 30%;
  --ifm-lightest-value: 50%;
  --ifm-color-primary: #34cb79;
  --ifm-color-primary-dark: rgb(49, 175, 106);
  --ifm-color-primary-darker: rgb(42, 163, 96);
  --ifm-color-primary-darkest: rgb(36, 141, 83);
  --ifm-color-primary-light: rgb(101, 226, 157);
  --ifm-color-primary-lighter: rgb(102, 230, 159);
  --ifm-color-primary-lightest: rgb(149, 243, 191);
  --ifm-color-secondary: rgb(51, 187, 96);
  --ifm-color-secondary-dark: rgb(52, 187, 96);
  --ifm-color-secondary-darker: rgb(53, 187, 98);
  --ifm-color-secondary-darkest: rgb(54, 187, 99);
  --ifm-color-secondary-light: rgb(50, 187, 96);
  --ifm-color-secondary-lighter: rgb(45, 187, 96);
  --ifm-color-secondary-lightest: rgb(40, 187, 96);
  --ifm-color-success: #00a400;
  --ifm-color-success-dark: #009400;
  --ifm-color-success-darker: #008b00;
  --ifm-color-success-darkest: #007300;
  --ifm-color-success-light: #26b226;
  --ifm-color-success-lighter: #4dbf4d;
  --ifm-color-success-lightest: #80d280;
  --ifm-color-info: #54c7ec;
  --ifm-color-info-dark: #4cb3d4;
  --ifm-color-info-darker: #47a9c9;
  --ifm-color-info-darkest: #3b8ba5;
  --ifm-color-info-light: #6ecfef;
  --ifm-color-info-lighter: #87d8f2;
  --ifm-color-info-lightest: #aae3f6;
  --ifm-color-warning: #ffba00;
  --ifm-color-warning-dark: #e6a700;
  --ifm-color-warning-darker: #d99e00;
  --ifm-color-warning-darkest: #b38200;
  --ifm-color-warning-light: #ffc426;
  --ifm-color-warning-lighter: #ffcf4d;
  --ifm-color-warning-lightest: #ffdd80;
  --ifm-color-danger: #fa383e;
  --ifm-color-danger-dark: #e13238;
  --ifm-color-danger-darker: #d53035;
  --ifm-color-danger-darkest: #af272b;
  --ifm-color-danger-light: #fb565b;
  --ifm-color-danger-lighter: #fb7478;
  --ifm-color-danger-lightest: #fd9c9f;
  --ifm-color-white: #fff;
  --ifm-color-black: #000;
  --ifm-color-gray-0: var(--ifm-color-white);
  --ifm-color-gray-100: #f5f6f7;
  --ifm-color-gray-200: #ebedf0;
  --ifm-color-gray-300: #dadde1;
  --ifm-color-gray-400: #ccd0d5;
  --ifm-color-gray-500: #bec3c9;
  --ifm-color-gray-600: #8d949e;
  --ifm-color-gray-700: #606770;
  --ifm-color-gray-800: #444950;
  --ifm-color-gray-900: #1c1e21;
  --ifm-color-navy-blue: #344154;
  --ifm-color-gray-1000: var(--ifm-color-black);
  --ifm-color-emphasis-0: var(--ifm-color-gray-0);
  --ifm-color-emphasis-100: var(--ifm-color-gray-100);
  --ifm-color-emphasis-200: var(--ifm-color-gray-200);
  --ifm-color-emphasis-300: var(--ifm-color-gray-300);
  --ifm-color-emphasis-400: var(--ifm-color-gray-400);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-600);
  --ifm-color-emphasis-700: var(--ifm-color-gray-700);
  --ifm-color-emphasis-800: var(--ifm-color-gray-800);
  --ifm-color-emphasis-900: var(--ifm-color-gray-900);
  --ifm-color-emphasis-1000: var(--ifm-color-gray-1000);
  --ifm-color-content: var(--ifm-color-emphasis-900);
  --ifm-color-content-inverse: var(--ifm-color-emphasis-0);
  --ifm-color-content-secondary: #606770;
  --ifm-background-color: #ebebee;
  --ifm-background-surface-color: var(--ifm-color-content-inverse);
  --ifm-global-border-width: 1px;
  --ifm-global-radius: 0.4rem;
  --ifm-hover-overlay: rgba(0, 0, 0, 0.05);
  --ifm-font-base-color-inverse: var(--ifm-color-content-inverse);
  --ifm-font-secondary-color: var(--ifm-color-content-secondary);
  --ifm-font-family-base: Poppins, Roboto, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --ifm-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --ifm-font-size-base: 15px;
  --ifm-font-weight-light: 300;
  --ifm-font-weight-normal: 400;
  --ifm-font-weight-semibold: 500;
  --ifm-font-weight-bold: 700;
  --ifm-font-weight-base: var(--ifm-font-weight-normal);
  --ifm-line-height-base: 1.5;
  --ifm-global-spacing: 1rem;
  --ifm-spacing-vertical: var(--ifm-global-spacing);
  --ifm-spacing-horizontal: var(--ifm-global-spacing);
  --ifm-transition-fast: 200ms;
  --ifm-transition-slow: 400ms;
  --ifm-global-shadow-lw: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --ifm-global-shadow-md: 0 5px 40px rgba(0, 0, 0, 0.2);
  --ifm-global-shadow-tl: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --ifm-z-index-dropdown: 100;
  --ifm-z-index-fixed: 200;
  --ifm-z-index-overlay: 400;
  --ifm-link-color: var(--ifm-color-primary);
  --ifm-link-decoration: none;
  --ifm-link-hover-color: var(--ifm-link-color);
  --ifm-link-hover-decoration: underline;
  --ifm-paragraph-margin-bottom: var(--ifm-leading);
  --ifm-blockquote-color: #6a737d;
  --ifm-blockquote-font-size: var(--ifm-font-size-base);
  --ifm-blockquote-padding-horizontal: 1rem;
  --ifm-blockquote-padding-vertical: 1rem;
  --ifm-hr-border-color: var(--ifm-color-emphasis-500);
  --ifm-hr-border-width: 1px;
  --ifm-hr-margin-vertical: 1.5rem;
  --ifm-footer-background-color: var(--ifm-color-navy-blue);
  --ifm-footer-color: inherit;
  --ifm-footer-link-color: var(--ifm-color-emphasis-700);
  --ifm-footer-link-hover-color: var(--ifm-color-primary);
  --ifm-footer-link-horizontal-spacing: 0.5rem;
  --ifm-footer-padding-horizontal: calc(var(--ifm-spacing-horizontal) * 2);
  --ifm-footer-padding-vertical: calc(var(--ifm-spacing-vertical) * 0.4);
  --ifm-footer-title-color: inherit;
  --ifm-scrollbar-background: #f1f1f1;
  --ifm-scrollbar-thumb: rgb(196, 196, 196);
  --ifm-scrollbar-hover: #555;
  --ifm-font-base-color: var(--ifm-color-gray-700);
  --ifm-card-background: var(--ifm-color-white);
  --box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
  --ifm-footer-background: #242526;
  --ifm-footer-base-color: #f1f1f1;
  --ifm-title-color: #000;

  --ifm-border-nav: var(--ifm-color-gray-300);
  --ifm-nav-text: var(--ifm-color-gray-800);
  --ifm-nav-button: var(--ifm-color-gray-400);
  --ifm-nav-menu-hover: var(--ifm-color-gray-400);
  --ifm-side-link: #f1f0f0;
  --ifm-border-color: var(--ifm-color-gray-300);

  --ifm-bg: #f5f2f8;
  --text-color: var(--ifm-nav-text);
  --nav-size: 60px;
  --border: 1px solid var(--ifm-border-nav);
  --border-radius: 8px;
  --speed: 300ms;
  --buton-bg: var(--ifm-nav-button);

  --primary-color: var(--ifm-color-primary);
  --title-color: #322153;
  --text-color: var(--ifm-font-base-color);
  --input-border: #6c6c80;
}

html[data-theme='dark'] {
  --ifm-color-emphasis-100: var(--ifm-color-gray-900);
  --ifm-color-emphasis-200: var(--ifm-color-gray-800);
  --ifm-color-emphasis-300: var(--ifm-color-gray-700);
  --ifm-color-emphasis-400: var(--ifm-color-gray-600);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-400);
  --ifm-color-emphasis-700: var(--ifm-color-gray-300);
  --ifm-color-emphasis-800: var(--ifm-color-gray-200);
  --ifm-color-emphasis-900: var(--ifm-color-gray-100);
  --ifm-background-color: #18191a;
  --ifm-card-background: #242526;
  --ifm-background-surface-color: #1e2125;
  --ifm-font-base-color: #6c6c80;
  --ifm-title-color: var(--ifm-color-white);
  --ifm-hover-overlay: hsla(0, 0%, 100%, 0.05);
  --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg alt="Arrow" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgba(255,255,255,0.6)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');
  --ifm-color-content-secondary: #fff;

  --ifm-border-color: var(--ifm-color-gray-800);

  --ifm-border-nav: #474a4d;
  --ifm-nav-text: #dadce1;
  --ifm-nav-button: #484a4d;
  --ifm-nav-menu-hover: #525357;
}

.toast-card {
  display: flex;
  align-items: center;
}

.toast-message {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--ifm-font-family-base);
  margin-left: 15px;
}

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 400px;
  box-sizing: border-box;
  color: #fff;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
  }
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #00bf9a;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #d4778a;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}
.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--default {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}

@-webkit-keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: rgba(255, 255, 255, 0.7);
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  -webkit-animation: Toastify__trackProgress linear 1 forwards;
          animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  transform-origin: right;
}
.Toastify__progress-bar--default {
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}
.Toastify__progress-bar--dark {
  background: #bb86fc;
}
@-webkit-keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@-webkit-keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@-webkit-keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
  -webkit-animation-name: Toastify__bounceInLeft;
          animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
  -webkit-animation-name: Toastify__bounceInRight;
          animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  -webkit-animation-name: Toastify__bounceInDown;
          animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  -webkit-animation-name: Toastify__bounceInUp;
          animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
  -webkit-animation-name: Toastify__bounceOutLeft;
          animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
  -webkit-animation-name: Toastify__bounceOutRight;
          animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  -webkit-animation-name: Toastify__bounceOutUp;
          animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  -webkit-animation-name: Toastify__bounceOutDown;
          animation-name: Toastify__bounceOutDown;
}

@-webkit-keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  -webkit-animation-name: Toastify__zoomIn;
          animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  -webkit-animation-name: Toastify__zoomOut;
          animation-name: Toastify__zoomOut;
}

@-webkit-keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@-webkit-keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  -webkit-animation-name: Toastify__flipIn;
          animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  -webkit-animation-name: Toastify__flipOut;
          animation-name: Toastify__flipOut;
}

@-webkit-keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@-webkit-keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@-webkit-keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  -webkit-animation-name: Toastify__slideInLeft;
          animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  -webkit-animation-name: Toastify__slideInRight;
          animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  -webkit-animation-name: Toastify__slideInDown;
          animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  -webkit-animation-name: Toastify__slideInUp;
          animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
  -webkit-animation-name: Toastify__slideOutLeft;
          animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
  -webkit-animation-name: Toastify__slideOutRight;
          animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  -webkit-animation-name: Toastify__slideOutUp;
          animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  -webkit-animation-name: Toastify__slideOutDown;
          animation-name: Toastify__slideOutDown;
}

/*# sourceMappingURL=ReactToastify.css.map */

:root {
  --primary-color: #34cb79;
  --title-color: #322153;
  --text-color: #6c6c80;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: var(--ifm-background-color);
  -webkit-font-smoothing: antialiased;
  color: #6c6c80;
  color: var(--text-color);
  scroll-behavior: smooth;
}

body,
input,
button {
  font-family: Poppins, Roboto, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #322153;
  color: var(--title-color);
  font-family: Ubuntu;
}

a {
  color: var(--ifm-link-color);
  -webkit-text-decoration: var(--ifm-link-decoration);
  text-decoration: var(--ifm-link-decoration);
  transition: -webkit-filter 0.2s ease;
  transition: filter 0.2s ease;
  transition: filter 0.2s ease, -webkit-filter 0.2s ease;
}

a:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

a:active,
a:hover {
  outline-width: 0;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: var(--ifm-scrollbar-background);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--ifm-scrollbar-thumb);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ifm-scrollbar-hover);
}

.text--center {
  text-align: center;
}

.full-button {
  /* text-transform: uppercase; */
  width: 100%;
  border-radius: 15px !important;
}

.full-button-45 {
  height: 45px;
}

.full-button-35 {
  height: 35px;
}

.round-button {
  background-image: linear-gradient(to bottom left, var(--ifm-color-secondary), var(--ifm-color-primary), var(--ifm-color-secondary));
  background-size: 200% 200%;
  background-position: top right;
  color: var(--ifm-color-white);
  border: 0 !important;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: all 0.2s ease;
}

.round-button:hover:enabled {
  background-position: bottom left;
  transition: 0.3s ease-in-out;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
}

.round-button:disabled,
.round-button[disabled] {
  cursor: not-allowed;
  border: 0px;
  background-image: linear-gradient(to bottom left, var(--ifm-color-gray-300), var(--ifm-color-gray-300), var(--ifm-color-gray-300));
  color: #666666;
}

textarea {
  flex: 1 1;
  background: #f0f0f5;
  font-family: var(--ifm-font-family-base);
  color: var(--input-border) !important;
  font-size: 16px;
  border: 1px solid transparent;
  outline: none;
  transition: ease-in-out, border 0.35s ease-in-out;
  width: 100%;
  border-radius: 8px;
  height: 110px !important;
  max-height: 150px !important;
  padding: 10px 10px;
  resize: none !important;
}

textarea:focus {
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 1px var(--ifm-color-primary);
  border-color: var(--ifm-color-primary);
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  scroll-behavior: smooth;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--ifm-card-background);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid var(--ifm-border-color);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  height: 50px;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0 !important;
  line-height: 1.5 !important;
  color: var(--title-color) !important;
  font-weight: 600 !important;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid var(--ifm-border-color);
  height: 60px;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: var(--ifm-font-base-color);
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  outline: none;
  transition: 0.7s;
}

.close:hover,
.close:focus {
  text-decoration: none;
  opacity: 0.75;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.footer-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-buttons button{
  width: 40% !important;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.dropzone {
  height: 80px;
  background: #E1FAEC;
  border-radius: 10px;
  width: 100%;
  display: flow-root;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  outline: 0;
  text-align: center;
  padding: 20px;
  border: 1px dashed #4ECB79;
}

.dropzone img {
  width: 100%;
  height: 100%; 
  object-fit: contain;
}

input[type='file'] {
  display: none;
}

.dropzone p {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  border-radius: 10px;
  border: 1px dashed #4ECB79;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
}

.dropzone p svg {
  color: #4ECB79;
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
}
form {
  padding: 5px;
  width: 100%;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
}

form h1 {
  font-size: 36px;
}

form fieldset {
  margin-top: 5px;
  min-inline-size: auto;
  border: 0;
}

form legend {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

form legend h2 {
  font-size: 24px;
  color: var(--title-color);
  font-weight: 500;
}

form legend span {
  font-size: 14px;
  font-weight: normal;
  color: var(--text-color);
}

form .field-group {
  flex: 1 1;
  display: flex;
}

form .field {
  flex: 1 1;

  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

form .field input[type='text'],
form .field input[type='email'],
form .field input[type='number'],
form .field input[type='tel'] {
  flex: 1 1;
  background: #f0f0f5;
  border-radius: 8px;
  border: 0;
  padding: 10px 10px;
  font-size: 16px;
  color: var(--input-border);
  border: 1px solid transparent;
  outline: none;
  transition: ease-in-out, border 0.35s ease-in-out;
}

form .field .adress-input {
  margin-top: 15px;
}

form .field input[type='text']:focus,
form .field input[type='email']:focus,
form .field input[type='number']:focus,
form .field input[type='tel']:focus {
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 1px var(--ifm-color-primary);
  border-color: var(--ifm-color-primary);
}

form .field input:disabled {
  height: 30px;
  padding: 5px;
}

form .field select {
  flex: 1 1;
  background: #f0f0f5;
  border-radius: 8px;
  border: 0;
  padding: 10px 10px;
  font-size: 16px;
  color: var(--input-border);
  border: 1px solid transparent;
  outline: none;
  transition: ease-in-out, border 0.35s ease-in-out;
}

form .field select:focus {
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 1px var(--ifm-color-primary);
  border-color: var(--ifm-color-primary);
}

.field .point-image {
  border-radius: 50%;
  width: 50%;
}

.point-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.field .delete-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field .delete-button svg {
  cursor: pointer;
  transition: -webkit-filter 0.7s ease;
  transition: filter 0.7s ease;
  transition: filter 0.7s ease, -webkit-filter 0.7s ease;
}

.field .delete-button svg:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

select option {
  flex: 1 1;
  background: #f0f0f5;
  border-radius: 8px;
  border: 0;
  padding: 10px 10px;
  font-size: 16px;
  color: var(--input-border);
  border: 1px solid transparent;
  outline: none;
}

form .input-fieldgroup {
  width: 98%;
}

form .field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 1;
  background: #f0f0f5;
  border-radius: 8px;
  border: 0;
  padding: 16px 24px;
  font-size: 16px;
  color: #6c6c80;
}

form .field input::-webkit-input-placeholder {
  color: #a0a0b2;
}

form .field input:-ms-input-placeholder {
  color: #a0a0b2;
}

form .field input::-ms-input-placeholder {
  color: #a0a0b2;
}

form .field input::placeholder {
  color: #a0a0b2;
}

form .field label {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}

form .field :disabled {
  cursor: not-allowed;
}

form .field-group .field + .field {
  margin-left: 24px;
}

form .field-group input + input {
  margin-left: 24px;
}

form .field-check {
  flex-direction: row;
  align-items: center;
}

form .field-check input[type='checkbox'] {
  background: #f0f0f5;
}

form .field-check label {
  margin: 0 0 0 8px;
}

form .leaflet-container {
  width: 100%;
  height: 350px;
  border-radius: 8px;
  margin-bottom: 24px;
}

form button {
  width: 260px;
  height: 56px;
  background: var(--primary-color);
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  align-self: flex-end;
  margin-top: 40px;
  transition: background-color 0.2s;
  cursor: pointer;
}

form button:hover {
  background: #2fb86e;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  gap: 16px;
  list-style: none;
}

.items-grid li {
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  height: 130px;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.7ms ease;
}

.items-grid li:hover {
  -webkit-filter: brightness(0.95);
          filter: brightness(0.95);
}

.items-grid li span {
  flex: 1 1;
  margin-top: 12px;

  display: flex;
  align-items: center;
  color: var(--title-color);
  font-size: 18px;
  line-height: 1.2;
}

.items-grid li.selected {
  background: #e1faec;
  border: 2px solid #34cb79;
}

@media (max-width: 500px) {
  .items-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    gap: 16px;
  }
}

/* NavBar */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  display: flex;
  height: var(--nav-size);
  /* background-color: var(--ifm-bg); */
  padding: 0 1rem;
  position: relative;
  /* box-shadow: var(--box-shadow); */
  /* -webkit-box-shadow: var(--box-shadow); */
}

/* <ul> */
.navbar-start {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.navbar-start .navbar-logo {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 15px;
}

.navbar-start span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: 500;
  text-transform: uppercase;
}

.nav-logo img {
  max-width: 200px;
  margin-top: 10px;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  right: 0;
  position: absolute;
  margin-right: 20px;
}

.navbar-nav li+li{
  margin-left: 10px;
}

.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-profile {
  width: calc(var(--nav-size) * 2.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-profile span {
  margin-left: 10px;
}

.icon-profile {
  width: calc(var(--nav-size) * 2.4);
  color: var(--text-color);
  height: 36px;
  background-color: transparent;
  border-radius: 18px;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-profile:hover {
  background-color: var(--ifm-nav-menu-hover);
  color: var(--text-color);
}

.icon-profile-active {
  width: calc(var(--nav-size) * 2.4);
  color: var(--ifm-color-primary-light);
  font-weight: 600;
  height: 36px;
  background-color: transparent;
  border-radius: 18px;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ifm-nav-menu-hover);
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-profile-active:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.icon-profile svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

.avatar-profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  /* background-color: var(--buton-bg); */
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  cursor: pointer;
}

.icon-button:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.icon-avatar {
  --button-size: calc(var(--nav-size) * 0.6);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  margin: 2px 10px 2px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
}

.icon-avatar:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.icon-right svg,
.icon-button svg {
  fill: var(--ifm-color-primary-dark);
  width: 25px;
  height: 25px;
}

.arrow {
  fill: var(--ifm-color-primary-dark);
  width: 25px;
  height: 25px;
}

.icon-button-color svg {
  width: 20px;
  height: 20px;
  fill: var(--ifm-color-primary);
}

/* Dropdown Menu */
.dropdown {
  z-index: 200;
  position: absolute;
  top: 58px;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--ifm-bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  box-shadow: var(--box-shadow);
  -webkit-box-shadow: var(--box-shadow);
}

.menu {
  width: 100%;
}

.menu-item {
  color: var(--text-color);
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  cursor: pointer;
  font-size: 18px;
}

.menu-item h2 {
  font-size: 23px;
  margin-bottom: 0 !important;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  -webkit-filter: none;
          filter: none;
}

.menu-item:hover {
  background-color: var(--ifm-nav-menu-hover);
  color: var(--text-color);
  -webkit-filter: brightness(1) !important;
          filter: brightness(1) !important;
}

.icon-right {
  margin-left: auto;
}

.menu a + a {
  margin-top: 5px;
}

.menu span + a {
  margin-top: 5px;
}

.menu a + span {
  margin-top: 5px;
}

.menu span + span {
  margin-top: 5px;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
  box-sizing: border-box;
  padding: 1rem;
  top: 0;
  left: 0;
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease-in;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.menu-terciary-enter {
  transform: translateX(1500%);
}
.menu-terciary-enter-active {
  transform: translateX(0%);
  transition: var(--speed) ease-out;
}

.menu-terciary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.drop-active {
  background-color: var(--ifm-nav-menu-hover);
  color: var(--ifm-color-primary-dark);
  font-weight: 600;
}

#page-home {
  height: calc(100vh - 60px);
  background: url(/static/media/home-background.fe2283e1.svg) no-repeat;
  background-position: bottom right;
}

#page-home .content {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#page-home .content .logo {
  max-width: 220px;
}

@media (max-width: 1320px) {
  #page-home {
    background-size: 60%;
  }
}

@media (max-width: 907px) {
  #page-home {
    background-size: 40%;
  }
}

@media (max-width: 722px) {
  #page-home {
    background-size: 45%;
    height: 110vh;
  }
}

@media (max-width: 440px) {
  #page-home {
    height: 115vh;
  }
}
main {
  flex: 1 1;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

main h1 {
  font-size: 54px;
  color: var(--title-color);
}

main p {
  font-size: 24px;
  margin-top: 24px;
  line-height: 38px;
}

main a {
  width: 100%;
  max-width: 360px;
  height: 72px;
  background: var(--primary-color);
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 18px;
  margin-top: 40px;
}

main a span {
  display: block;
  background: rgba(0, 0, 0, 0.08);
  width: 72px;
  height: 72px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

main a span svg {
  color: #fff;
  width: 20px;
  height: 20px;
}

main a strong {
  flex: 1 1;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

main a:hover {
  background: #2fb86e;
}

@media (max-width: 900px) {
  main {
    align-items: center;
    text-align: center;
  }

  main h1 {
    font-size: 42px;
  }

  main p {
    font-size: 24px;
  }
}

.Loader{
      width: 100%;
}
.feed {
  /* flex: 1 1; */
  max-width: 100%;
  width: 100%;
  display: block;
  /* flex-direction: column; */
  /* justify-content: center; */
}

.filters {
  margin-top: 30px;
  background: var(--ifm-card-background);
  border-radius: 8px;
  height: 190px;
  padding: 10px;
  position: relative;
}

.filters .search-button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.search-disable {
  cursor: not-allowed !important;
}

.search-button svg {
  transition: -webkit-filter 0.7s ease;
  transition: filter 0.7s ease;
  transition: filter 0.7s ease, -webkit-filter 0.7s ease;
}

.search-button svg:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.filters h2 {
  color: var(--title-color);
  font-weight: 600;
}

.filters input {
  flex: 1 1;
  background: #f0f0f5;
  border-radius: 8px;
  border: 0;
  padding: 10px 10px;
  font-size: 16px;
  color: var(--input-border);
  border: 1px solid transparent;
  outline: none;
  transition: ease-in-out, border 0.35s ease-in-out;
}

.filters input:focus {
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 1px var(--ifm-color-primary);
  border-color: var(--ifm-color-primary);
}

.filters label {
  font-size: 16px;
  font-weight: 600;
}

.filters .filters-value {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.filters .field {
  width: 100%;
  display: inline-grid;
}

.filters-items {
  position: absolute;
  right: 0;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.filters-items label{
  margin-right: 20px;
 }

.filters-items .items-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  list-style: none;
}

.filters-items .items-grid li {
  background: #f5f5f5;
  border: 2px solid #f5f5f5;
  height: 30px;
  width: 30px;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.7ms ease;
}

.filters-items .items-grid li img {
  max-width: 20px;
}

.filters-items .items-grid li.selected {
  background: #e1faec;
  border: 2px solid #34cb79;
}

.field label + input {
  margin-top: 5px;
}

.feed .points ul {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.feed .points ul li {
  background: var(--ifm-card-background);
  padding: 24px;
  border-radius: 8px;
  position: relative;
}
ul li .social {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

ul li .social a + a {
  margin-left: 10px;
}

.social .social-button {
  background: transparent !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 18px;
  margin-top: 0 !important;
}

li header {
  display: flex;
  align-items: center;
}

li header .info {
  margin-left: 20px;
}

li header .info strong {
  color: var(--title-color);
}

.info .email {
  margin: 0 !important;
  font-size: 16px;
}

.info .waste-items {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.waste-items div + div {
  margin-left: 10px;
}

.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
}

.tooltip img {
  width: 20px;
  position: relative;
  transition: -webkit-filter 0.7ms ease;
  transition: filter 0.7ms ease;
  transition: filter 0.7ms ease, -webkit-filter 0.7ms ease;
}

.tooltip img:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}

.tooltip .tooltiptext {
  visibility: hidden;
  text-align: center !important;
  font-size: 16px;
  padding: 5px;
  width: 120px;
  background-color: var(--ifm-scrollbar-hover);
  color: var(--ifm-color-white);
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  display: inline;
  display: initial;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--ifm-scrollbar-hover) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

li .point-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

li .address {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 30px;
}

li .compound {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 5px !important;
  font-weight: 500;
}

@media (max-width: 900px) {
  .feed {
    align-items: center;
    text-align: left;
  }

  .feed h1 {
    font-size: 42px;
  }
}

@media (max-width: 750px) {
  .feed .points ul {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 14px;
  }
  .filters {
    height: 280px;
  }

  .filters .filters-value {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 14px;
  }
}

.cardfull {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px 0;
  background: var(--ifm-card-background);
  border-radius: 8px;
  padding: 30px;
  position: relative;
}

.cardfull h1 {
  text-align: center;
  font-weight: 600;
  font-size: 54px;
  color: var(--title-color);
}

.cardfull p {
  font-size: 24px;
  margin-top: 24px;
  line-height: 38px;
}

.cardfull a {
  width: 100%;
  max-width: 360px;
  height: 72px;
  background: var(--primary-color);
  border-radius: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 18px;
  margin-top: 40px;
}

.cardfull a span {
  display: block;
  background: rgba(0, 0, 0, 0.08);
  width: 72px;
  height: 72px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.cardfull a span svg {
  color: #fff;
  width: 20px;
  height: 20px;
}

.cardfull a strong {
  flex: 1 1;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.cardfull a:hover {
  background: #2fb86e;
}

.cardfull img {
  max-width: 230px;
}

@media (max-width: 900px) {
  .cardfull {
    align-items: center;
    text-align: center;
  }

  .cardfull h1 {
    font-size: 42px;
  }

  .cardfull p {
    font-size: 24px;
  }
}

