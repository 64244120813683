:root {
  --ifm-dark-value: 10%;
  --ifm-darker-value: 15%;
  --ifm-darkest-value: 30%;
  --ifm-light-value: 15%;
  --ifm-lighter-value: 30%;
  --ifm-lightest-value: 50%;
  --ifm-color-primary: #34cb79;
  --ifm-color-primary-dark: rgb(49, 175, 106);
  --ifm-color-primary-darker: rgb(42, 163, 96);
  --ifm-color-primary-darkest: rgb(36, 141, 83);
  --ifm-color-primary-light: rgb(101, 226, 157);
  --ifm-color-primary-lighter: rgb(102, 230, 159);
  --ifm-color-primary-lightest: rgb(149, 243, 191);
  --ifm-color-secondary: rgb(51, 187, 96);
  --ifm-color-secondary-dark: rgb(52, 187, 96);
  --ifm-color-secondary-darker: rgb(53, 187, 98);
  --ifm-color-secondary-darkest: rgb(54, 187, 99);
  --ifm-color-secondary-light: rgb(50, 187, 96);
  --ifm-color-secondary-lighter: rgb(45, 187, 96);
  --ifm-color-secondary-lightest: rgb(40, 187, 96);
  --ifm-color-success: #00a400;
  --ifm-color-success-dark: #009400;
  --ifm-color-success-darker: #008b00;
  --ifm-color-success-darkest: #007300;
  --ifm-color-success-light: #26b226;
  --ifm-color-success-lighter: #4dbf4d;
  --ifm-color-success-lightest: #80d280;
  --ifm-color-info: #54c7ec;
  --ifm-color-info-dark: #4cb3d4;
  --ifm-color-info-darker: #47a9c9;
  --ifm-color-info-darkest: #3b8ba5;
  --ifm-color-info-light: #6ecfef;
  --ifm-color-info-lighter: #87d8f2;
  --ifm-color-info-lightest: #aae3f6;
  --ifm-color-warning: #ffba00;
  --ifm-color-warning-dark: #e6a700;
  --ifm-color-warning-darker: #d99e00;
  --ifm-color-warning-darkest: #b38200;
  --ifm-color-warning-light: #ffc426;
  --ifm-color-warning-lighter: #ffcf4d;
  --ifm-color-warning-lightest: #ffdd80;
  --ifm-color-danger: #fa383e;
  --ifm-color-danger-dark: #e13238;
  --ifm-color-danger-darker: #d53035;
  --ifm-color-danger-darkest: #af272b;
  --ifm-color-danger-light: #fb565b;
  --ifm-color-danger-lighter: #fb7478;
  --ifm-color-danger-lightest: #fd9c9f;
  --ifm-color-white: #fff;
  --ifm-color-black: #000;
  --ifm-color-gray-0: var(--ifm-color-white);
  --ifm-color-gray-100: #f5f6f7;
  --ifm-color-gray-200: #ebedf0;
  --ifm-color-gray-300: #dadde1;
  --ifm-color-gray-400: #ccd0d5;
  --ifm-color-gray-500: #bec3c9;
  --ifm-color-gray-600: #8d949e;
  --ifm-color-gray-700: #606770;
  --ifm-color-gray-800: #444950;
  --ifm-color-gray-900: #1c1e21;
  --ifm-color-navy-blue: #344154;
  --ifm-color-gray-1000: var(--ifm-color-black);
  --ifm-color-emphasis-0: var(--ifm-color-gray-0);
  --ifm-color-emphasis-100: var(--ifm-color-gray-100);
  --ifm-color-emphasis-200: var(--ifm-color-gray-200);
  --ifm-color-emphasis-300: var(--ifm-color-gray-300);
  --ifm-color-emphasis-400: var(--ifm-color-gray-400);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-600);
  --ifm-color-emphasis-700: var(--ifm-color-gray-700);
  --ifm-color-emphasis-800: var(--ifm-color-gray-800);
  --ifm-color-emphasis-900: var(--ifm-color-gray-900);
  --ifm-color-emphasis-1000: var(--ifm-color-gray-1000);
  --ifm-color-content: var(--ifm-color-emphasis-900);
  --ifm-color-content-inverse: var(--ifm-color-emphasis-0);
  --ifm-color-content-secondary: #606770;
  --ifm-background-color: #ebebee;
  --ifm-background-surface-color: var(--ifm-color-content-inverse);
  --ifm-global-border-width: 1px;
  --ifm-global-radius: 0.4rem;
  --ifm-hover-overlay: rgba(0, 0, 0, 0.05);
  --ifm-font-base-color-inverse: var(--ifm-color-content-inverse);
  --ifm-font-secondary-color: var(--ifm-color-content-secondary);
  --ifm-font-family-base: Poppins, Roboto, Segoe UI, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --ifm-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --ifm-font-size-base: 15px;
  --ifm-font-weight-light: 300;
  --ifm-font-weight-normal: 400;
  --ifm-font-weight-semibold: 500;
  --ifm-font-weight-bold: 700;
  --ifm-font-weight-base: var(--ifm-font-weight-normal);
  --ifm-line-height-base: 1.5;
  --ifm-global-spacing: 1rem;
  --ifm-spacing-vertical: var(--ifm-global-spacing);
  --ifm-spacing-horizontal: var(--ifm-global-spacing);
  --ifm-transition-fast: 200ms;
  --ifm-transition-slow: 400ms;
  --ifm-global-shadow-lw: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --ifm-global-shadow-md: 0 5px 40px rgba(0, 0, 0, 0.2);
  --ifm-global-shadow-tl: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --ifm-z-index-dropdown: 100;
  --ifm-z-index-fixed: 200;
  --ifm-z-index-overlay: 400;
  --ifm-link-color: var(--ifm-color-primary);
  --ifm-link-decoration: none;
  --ifm-link-hover-color: var(--ifm-link-color);
  --ifm-link-hover-decoration: underline;
  --ifm-paragraph-margin-bottom: var(--ifm-leading);
  --ifm-blockquote-color: #6a737d;
  --ifm-blockquote-font-size: var(--ifm-font-size-base);
  --ifm-blockquote-padding-horizontal: 1rem;
  --ifm-blockquote-padding-vertical: 1rem;
  --ifm-hr-border-color: var(--ifm-color-emphasis-500);
  --ifm-hr-border-width: 1px;
  --ifm-hr-margin-vertical: 1.5rem;
  --ifm-footer-background-color: var(--ifm-color-navy-blue);
  --ifm-footer-color: inherit;
  --ifm-footer-link-color: var(--ifm-color-emphasis-700);
  --ifm-footer-link-hover-color: var(--ifm-color-primary);
  --ifm-footer-link-horizontal-spacing: 0.5rem;
  --ifm-footer-padding-horizontal: calc(var(--ifm-spacing-horizontal) * 2);
  --ifm-footer-padding-vertical: calc(var(--ifm-spacing-vertical) * 0.4);
  --ifm-footer-title-color: inherit;
  --ifm-scrollbar-background: #f1f1f1;
  --ifm-scrollbar-thumb: rgb(196, 196, 196);
  --ifm-scrollbar-hover: #555;
  --ifm-font-base-color: var(--ifm-color-gray-700);
  --ifm-card-background: var(--ifm-color-white);
  --box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
  --ifm-footer-background: #242526;
  --ifm-footer-base-color: #f1f1f1;
  --ifm-title-color: #000;

  --ifm-border-nav: var(--ifm-color-gray-300);
  --ifm-nav-text: var(--ifm-color-gray-800);
  --ifm-nav-button: var(--ifm-color-gray-400);
  --ifm-nav-menu-hover: var(--ifm-color-gray-400);
  --ifm-side-link: #f1f0f0;
  --ifm-border-color: var(--ifm-color-gray-300);

  --ifm-bg: #f5f2f8;
  --text-color: var(--ifm-nav-text);
  --nav-size: 60px;
  --border: 1px solid var(--ifm-border-nav);
  --border-radius: 8px;
  --speed: 300ms;
  --buton-bg: var(--ifm-nav-button);

  --primary-color: var(--ifm-color-primary);
  --title-color: #322153;
  --text-color: var(--ifm-font-base-color);
  --input-border: #6c6c80;
}

html[data-theme='dark'] {
  --ifm-color-emphasis-100: var(--ifm-color-gray-900);
  --ifm-color-emphasis-200: var(--ifm-color-gray-800);
  --ifm-color-emphasis-300: var(--ifm-color-gray-700);
  --ifm-color-emphasis-400: var(--ifm-color-gray-600);
  --ifm-color-emphasis-500: var(--ifm-color-gray-500);
  --ifm-color-emphasis-600: var(--ifm-color-gray-400);
  --ifm-color-emphasis-700: var(--ifm-color-gray-300);
  --ifm-color-emphasis-800: var(--ifm-color-gray-200);
  --ifm-color-emphasis-900: var(--ifm-color-gray-100);
  --ifm-background-color: #18191a;
  --ifm-card-background: #242526;
  --ifm-background-surface-color: #1e2125;
  --ifm-font-base-color: #6c6c80;
  --ifm-title-color: var(--ifm-color-white);
  --ifm-hover-overlay: hsla(0, 0%, 100%, 0.05);
  --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg alt="Arrow" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgba(255,255,255,0.6)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');
  --ifm-color-content-secondary: #fff;

  --ifm-border-color: var(--ifm-color-gray-800);

  --ifm-border-nav: #474a4d;
  --ifm-nav-text: #dadce1;
  --ifm-nav-button: #484a4d;
  --ifm-nav-menu-hover: #525357;
}
