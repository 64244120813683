@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;600;700&display=swap');

@import url('./variables.css');

:root {
  --primary-color: #34cb79;
  --title-color: #322153;
  --text-color: #6c6c80;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: var(--ifm-background-color);
  -webkit-font-smoothing: antialiased;
  color: var(--text-color);
  scroll-behavior: smooth;
}

body,
input,
button {
  font-family: Poppins, Roboto, Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
  font-family: Ubuntu;
}

a {
  color: var(--ifm-link-color);
  -webkit-text-decoration: var(--ifm-link-decoration);
  text-decoration: var(--ifm-link-decoration);
  transition: filter 0.2s ease;
}

a:hover {
  filter: brightness(1.2);
}

a:active,
a:hover {
  outline-width: 0;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: var(--ifm-scrollbar-background);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--ifm-scrollbar-thumb);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--ifm-scrollbar-hover);
}

.text--center {
  text-align: center;
}

.full-button {
  /* text-transform: uppercase; */
  width: 100%;
  border-radius: 15px !important;
}

.full-button-45 {
  height: 45px;
}

.full-button-35 {
  height: 35px;
}

.round-button {
  background-image: -webkit-gradient(linear, right top, left bottom, from(var(--ifm-color-secondary)), color-stop(var(--ifm-color-primary)), to(var(--ifm-color-secondary)));
  background-image: linear-gradient(to bottom left, var(--ifm-color-secondary), var(--ifm-color-primary), var(--ifm-color-secondary));
  background-size: 200% 200%;
  background-position: top right;
  color: var(--ifm-color-white);
  border: 0 !important;
  font-size: 13px;
  cursor: pointer;
  outline: none;
  font-weight: 700;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.round-button:hover:enabled {
  background-position: bottom left;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
}

.round-button:disabled,
.round-button[disabled] {
  cursor: not-allowed;
  border: 0px;
  background-image: linear-gradient(to bottom left, var(--ifm-color-gray-300), var(--ifm-color-gray-300), var(--ifm-color-gray-300));
  color: #666666;
}

textarea {
  flex: 1;
  background: #f0f0f5;
  font-family: var(--ifm-font-family-base);
  color: var(--input-border) !important;
  font-size: 16px;
  border: 1px solid transparent;
  outline: none;
  transition: ease-in-out, border 0.35s ease-in-out;
  width: 100%;
  border-radius: 8px;
  height: 110px !important;
  max-height: 150px !important;
  padding: 10px 10px;
  resize: none !important;
}

textarea:focus {
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 1px var(--ifm-color-primary);
  border-color: var(--ifm-color-primary);
}
